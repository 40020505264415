import React, { useState, useEffect } from 'react';

function Footer() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentDate = new Date();
            const newYear = currentDate.getFullYear();
            const isNewYear = currentDate.getMonth() === 0 && currentDate.getDate() === 1;

            if (isNewYear) {
                setCurrentYear(newYear);
            }
        }, 1000 * 60 * 60 * 24); 

        return () => {
            clearInterval(intervalId);
        };
    }, []); 

    return (
        <footer className="p-3 text-center">
            <p>Copyright © {currentYear} ICE | Powered by  <a href="https://ghsoft.co/" target='blank'><strong>GHSoft.co</strong></a></p>
        </footer>
    );
}

export default Footer;
