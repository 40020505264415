import React, { useState, useRef, useEffect } from 'react';
import logoICE from '../assets/images/Logo-ICE.png'
import { Helmet } from 'react-helmet';
import { db, storage } from "../firebase/firebaseconfig";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useLocation, useNavigate } from 'react-router-dom';

const InformacionMascotas = () => {
    const location = useLocation();
    const userId = location.state?.userId;

    const navigate = useNavigate();

    const [contactos, setContactos] = useState([
        { id: 1, parentesco: '', nombresCE: '', celularCE: '', parentescoCE: '' } 
    ]);

    const [nombres, setNombres] = useState('');
    const [foto, setFoto] = useState('');
    const [raza, setRaza] = useState('');
    const [edad, setEdad] = useState('');
    const [plandevacunacion, setPlanVacunacion] = useState('');
    const [alimento, setAlimento] = useState('');
    const [medicamentos, setMedicamentos] = useState('Ninguno');
    const [cuidadosEspeciales, setCuidadosEspeciales] = useState('Ninguno');
    const [codigoId, setCodigoId] = useState('');

    const handleFotoChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        try {
            const maxSizeInBytes = 500 * 1024;
            const storageRef = ref(storage, `fotosdeperfil/${file.name}`);
            let imageUrl;
    
            if (file.size > maxSizeInBytes) {
                const compressedImage = await compressImage(file, 300);
                await uploadBytes(storageRef, compressedImage);
                imageUrl = await getDownloadURL(storageRef);
            } else {
                await uploadBytes(storageRef, file);
                imageUrl = await getDownloadURL(storageRef);
            }
    
            setFoto(imageUrl);
        } catch (error) {
            console.error('Error al subir la imagen:', error);
        }
    };
    
    const compressImage = async (imageFile, maxWidth) => {
        const originalImage = new Image();
        originalImage.src = URL.createObjectURL(imageFile);
    
        await new Promise(resolve => {
            originalImage.onload = resolve;
        });
    
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
    
        const aspectRatio = originalImage.width / originalImage.height;
        const newWidth = maxWidth;
        const newHeight = newWidth / aspectRatio;
    
        canvas.width = newWidth;
        canvas.height = newHeight;
    
        ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
    
        return new Promise(resolve => {
            canvas.toBlob(resolve, 'image/jpeg');
        });
    };
    
    const handlePlanVacunacionChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        if (file.size > 900 * 1024) {
            alert("El archivo es demasiado grande. Por favor, seleccione un archivo más pequeño.");
            return;
        }
    
        try {
            const storageRef = ref(storage, `planesdevacunacion/${file.name}`);
            await uploadBytes(storageRef, file);
            const fileUrl = await getDownloadURL(storageRef);
    
            setPlanVacunacion(fileUrl);
        } catch (error) {
            console.error('Error al subir el archivo:', error);
        }
    };
    
    const nombresCERef = useRef([]);
    const celularCERef = useRef([]);
    const parentescoCERef = useRef([]);

    const agregarContacto = () => {
        const nuevoId = contactos.length + 1;
        const nuevoContacto = { id: nuevoId, parentesco: '', nombresCE: '', celularCE: '', parentescoCE: '' };
        setContactos([...contactos, nuevoContacto]);
    };
    
    const handleParentescoChange = (event, contactoId) => {
        const updatedContactos = contactos.map(contacto => {
          if (contacto.id === contactoId) {
            return { ...contacto, parentesco: event.target.value };
          }
          return contacto;
        });
        setContactos(updatedContactos);
    };

    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");


    const guardarInformacion = async () => {
        try {
            if (!nombres || !foto || !raza || !edad || !codigoId) {
                setError("Por favor, complete todos los campos obligatorios.");
                return;
            }

            setIsLoading(true);
    
            const idExiste = await verificarIdExistente(codigoId);
    
            if (!idExiste) {
                setError("El código ID no existe.");
                return;
            }
    
            const codigoIdEnUso = await verificarCodigoIdEnUso(codigoId);
    
            if (codigoIdEnUso) {
                setError("El código ID ya está en uso.");
                return;
            }
    
            const informacionMascotas = {
                nombres: nombres,
                foto: foto,
                raza: raza,
                edad: edad,
                plandevacunacion: plandevacunacion,
                alimento: alimento,
                medicamentos: medicamentos,
                cuidadosespeciales: cuidadosEspeciales,
                codigoid: codigoId,
                nombresContactoEmergencia: nombresCERef.current.map(ref => ref.value),
                celularContactoEmergencia: celularCERef.current.map(ref => ref.value),
                parentescoContactoEmergencia: parentescoCERef.current.map(ref => ref.value),
                userId: userId,
            };
    
            const datosAGuardar = {
                userId: userId,
                registrado: true,
                ...informacionMascotas,
            };
    
            const docRef = await addDoc(collection(db, "informacionMascotas"), datosAGuardar);
            console.log('Información guardada exitosamente en Firestore con ID: ', docRef.id);
            console.log('La información a guardar es: ', datosAGuardar);
            setRegistrationSuccess(true);
        } catch (error) {
            console.error('Error al guardar la información:', error);
            setError("No se pudo guardar la información. Por favor, inténtalo de nuevo más tarde.");
        } finally {
            setIsLoading(false);
        }
    };
    
    const verificarCodigoIdEnUso = async (codigoId) => {
        try {
            const querySnapshot = await getDocs(query(collection(db, "informacionMascotas"), where("codigoid", "==", codigoId)));
            return !querySnapshot.empty;
        } catch (error) {
            console.error('Error al verificar el código ID en uso:', error);
            return true;
        }
    };    
    
    const verificarIdExistente = async (id) => {
        try {
            const q = query(collection(db, 'idsMascotas'), where('id', '==', id));
            const querySnapshot = await getDocs(q);
    
            if (querySnapshot.size > 0) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error al verificar el ID en la colección idsMascotas:', error);
            return false;
        }
    };
    

    useEffect(() => {
        if (registrationSuccess) {
            const redirectTimer = setTimeout(() => {
                navigate(`/iniciar-sesion-mascotas`);
            }, 10000); 
    
            return () => clearTimeout(redirectTimer);
        }
    }, [registrationSuccess, navigate]);

    useEffect(() => {
        if (!userId) {
            navigate("/iniciar-sesion-mascotas");
        } else {
            console.log(`Usuario con el ID ${userId} autenticado correctamente.`);
        }
    }, [userId, navigate]);
    
    if (!userId) {
        return null;
    }    

    return (
        <div className="container-xxl mt-3">
            <Helmet>
                <title>Informacion mascotas | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="text-center">
                <img src={logoICE} alt="Logo ICE" width="200px"></img>
            </div>
            <form className="form-informacion-personal p-3 mt-3">
                <h1 className="mb-3">Información de tu mascota</h1>
                <div className="mb-3">
                    <input 
                        type="text"
                        value={nombres}
                        onChange={(e) => setNombres(e.target.value)}
                        className="form-control" 
                        placeholder="¿Cúal es su nombre?"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="foto" className="col-5 col-form-label">Foto *</label>
                    <input 
                        type="file"
                        onChange={handleFotoChange}
                        className="form-control"
                        accept="image/*"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="raza" className="col-5 col-form-label">Raza *</label>
                    <input 
                        type="text"
                        value={raza}
                        onChange={(e) => setRaza(e.target.value)}
                        className="form-control" 
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="edad" className="col-5 col-form-label">Edad *</label>
                    <input 
                        type="text"
                        value={edad}
                        onChange={(e) => setEdad(e.target.value)}
                        className="form-control" 
                        placeholder="Ej: 1 año"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="planDeVacunacion" className="col-5 col-form-label">Plan de vacunación</label>
                    <input 
                        type="file"
                        onChange={handlePlanVacunacionChange}
                        accept="image/*, .pdf"
                        className="form-control"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="alimento" className="col-5 col-form-label">Alimento</label>
                    <input 
                        type="text"
                        value={alimento}
                        onChange={(e) => setAlimento(e.target.value)}
                        className="form-control" 
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="medicamentos" className="col-5 col-form-label">Medicamentos</label>
                    <input 
                        type="text"
                        value={medicamentos}
                        onChange={(e) => setMedicamentos(e.target.value)}
                        className="form-control" 
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="cuiadadosEspeciales" className="col-5 col-form-label">Cuidados especiales</label>
                    <textarea
                        id="cuidadosEspeciales"
                        className="form-control"
                        value={cuidadosEspeciales}
                        onChange={(e) => setCuidadosEspeciales(e.target.value)} 
                        rows="1">     
                    </textarea>
                </div>
                <div className="mb-3 position-relative">
                    <label htmlFor="codigoId" className="col-5 col-form-label">
                        Código ID *
                        <span className="badge bg-primary position-absolute top-0 start-50 translate-middle mt-1">El código único ID lo encuentras en la placa.</span>
                    </label>
                    <input 
                        type="text"
                        value={codigoId}
                        onChange={(e) => setCodigoId(e.target.value)}
                        className="form-control"
                        placeholder="Introduce el código único ID que se encuentra en la placa"
                        required
                    />
                </div>
                <div className="mt-5">
                    <h2 className="mb-3">Contactos de emergencia</h2>
                    {contactos.map(contacto => (
                    <div className="accordion mb-5" key={contacto.id}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${contacto.id}`}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${contacto.id}`} aria-expanded="true" aria-controls={`collapse${contacto.id}`}>
                                    Contacto #{contacto.id} - {contacto.parentesco}
                                </button>
                            </h2>
                            <div id={`collapse${contacto.id}`} className="accordion-collapse collapse show" aria-labelledby={`heading${contacto.id}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="p-1">
                                        <div className="input-group mb-3">
                                            <input 
                                                type="text"
                                                id="nombresCE"
                                                ref={el => nombresCERef.current[contacto.id - 1] = el}
                                                className="form-control" 
                                                placeholder="Nombres"
                                                required
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input 
                                                type="number"
                                                id="celularCE"
                                                ref={el => celularCERef.current[contacto.id - 1] = el}
                                                className="form-control" 
                                                placeholder="Celular"
                                                required
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input 
                                                type="text"
                                                id="parentescoCE"
                                                ref={el => parentescoCERef.current[contacto.id - 1] = el}
                                                value={contacto.parentesco}
                                                onChange={(e) => handleParentescoChange(e, contacto.id)}
                                                className="form-control" 
                                                placeholder="Parentesco"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
                <div>
                    <button type="button" className="btn btn-primary" onClick={agregarContacto}>Agregar otro contacto</button>
                </div>
                {registrationSuccess && ( 
                    <div className="alert alert-success mt-5" role="alert">
                        ¡Información guardada con éxito! A partir de ahora, al escanear la placa de tu mascota, los datos estarán disponibles para acceso rápido en caso de emergencia. Si necesitas editar o actualizar su información en cualquier momento, puedes iniciar sesión con tu usuario y contraseña.
                    </div>
                )}
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="d-flex justify-content-end">
                    <button 
                        type="button" 
                        className="btn btn-success mt-5 mb-3" 
                        onClick={guardarInformacion} 
                        disabled={isLoading || registrationSuccess}
                    >
                        {isLoading ? "Guardando info..." : "Guardar Información"}
                </button>
                </div>
            </form>
        </div>
    );

}

export default InformacionMascotas;