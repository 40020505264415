import React, { useState, useEffect } from "react";
import logoICE from './assets/images/Logo-ICE.png'
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";
import { db } from "./firebase/firebaseconfig";
import { query, where, getDocs, collection } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faComment, faTruckMedical, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import Footer from "./components/footer";

const MascotasQR = () => {
    const location = useLocation();
    const idUnico = location.pathname.split("/")[2];

    const [userInfo, setUserInfo] = useState(null);
    const [infoNotFound, setInfoNotFound] = useState(false);

    useEffect(() => {
        console.log("ID único:", idUnico);
    
        if (!idUnico || idUnico.trim() === "") return;
    
        const fetchMascotaInfo = async () => {
            try {
                const infoQuery = query(collection(db, "informacionMascotas"), where("codigoid", "==", idUnico));
                const infoQuerySnapshot = await getDocs(infoQuery);
    
                if (infoQuerySnapshot.empty) {
                    console.log("No se encontró información para este código ID");
                    setInfoNotFound(true);
                    return;
                }
    
                const infoData = infoQuerySnapshot.docs[0].data();
                setUserInfo(infoData);
            } catch (error) {
                console.error("Error al obtener la información de la mascota:", error);
            }
        };
    
        fetchMascotaInfo();
    }, [idUnico]);

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]} ${match[3]}`;
        }
        return phoneNumber;
      };
      
      
    return (
        <div className="container-fluid vh-100 w-100 p-0">
            <Helmet>
                <title>QR info | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            {infoNotFound ? (
                    <div class="alert alert-warning m-5" role="alert">
                        <p>No se encontró información para esta mascota.</p>
                    </div>
              
            ) : (
                userInfo ? (
                    <div>
                        <div className="text-center mt-3 mb-5">
                            <img src={logoICE} alt="Logo ICE" width="200px"></img>
                        </div>
                        <div className="form-qr-headings p-4 pb-0">
                            <h1>Información de {userInfo.nombres}</h1>
                        </div>
                        <section>
                            <div className="mt-3 rounded-image-container mx-auto d-block">
                                <img src={userInfo.foto} className="rounded-image" width="150px" alt="Foto"/>
                            </div>
                        </section>
                        <section className="form-qr-mascotas p-4">
                            <div className="border-bottom mb-2 p-2">
                                <p><strong>{userInfo.nombres}</strong></p>
                                <p>{userInfo.edad}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Raza</span></p>
                                <p>{userInfo.raza}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Alimento</span></p>
                                <p>{userInfo.alimento}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Medicamentos</span></p>
                                <p>{userInfo.medicamentos}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Cuidados especiales</span></p>
                                <p>{userInfo.cuidadosespeciales}</p>
                            </div>
                            <div className="border-bottom mb-2 p-2">
                                <p><span>Carné de vacunas</span></p>
                                <a href={userInfo.plandevacunacion}>
                                    <button type="button" className="btn btn-info"><FontAwesomeIcon icon={faFileArrowDown} /> Descargar</button>
                                </a>
                            </div>
                        </section>
                        <div className="form-qr-headings p-4 pb-0">
                            <h2 className="mt-4">Contactos de emergencia</h2>
                        </div>
                        <section className="form-qr p-4">
                            {userInfo.parentescoContactoEmergencia.map((parentesco, index) => (
                                <div className="border-bottom mb-2 p-2" key={index}>
                                    <p><span>{parentesco}</span></p>
                                    <p>{userInfo.nombresContactoEmergencia[index]}</p>
                                    <p>
                                        <a href={`tel:${userInfo.celularContactoEmergencia[index].replace(/\D/g, '')}`}>
                                            {formatPhoneNumber(userInfo.celularContactoEmergencia[index])}
                                        </a>
                                    </p>
                                    <div className="row">
                                        <div className="col-5">
                                            <a href={`tel:${userInfo.celularContactoEmergencia[index].replace(/\D/g, '')}`}>
                                                <button type="button" className="btn btn-tel"><FontAwesomeIcon icon={faPhone} /> Llamar</button>
                                            </a>
                                        </div>
                                        <div className="col-7 d-flex justify-content-end">
                                            <a href={`https://wa.me/57${userInfo.celularContactoEmergencia[index].replace(/\D/g, '')}?text=Hola%20${userInfo.nombresContactoEmergencia[index]}%20te%20escribo%20porque%20me%20encuentro%20con%20${userInfo.nombres}`}>
                                                <button type="button" className="btn btn-whatsapp"><FontAwesomeIcon icon={faComment} /> Enviar WhatsApp</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </section>
                        <section className="p-3">
                            <a href="tel:123">
                                <button type="button" className="btn btn-emergency"><FontAwesomeIcon icon={faTruckMedical} /> Llamar al 123</button>
                            </a>
                        </section>
                        <Footer />
                    </div>
                ) : (
                    <div class="spinner-border m-5" role="status">
                        <span class="visually-hidden">Cargando información...</span>
                    </div>  
                )
            )}
        </div>
    );
};

export default MascotasQR;