import React, { useState, useEffect } from "react";
import logoICE from '../assets/images/Logo-ICE.png'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { db, auth } from "../firebase/firebaseconfig";
import { query, where, getDocs, collection, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faEdit, faSave, faFile } from '@fortawesome/free-solid-svg-icons';

const HomeMascotas = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const userId = location.state?.userId;

    const [userInfo, setUserInfo] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(!!userId);
    const [isEditing, setIsEditing] = useState(false);
    const [editedUserInfo, setEditedUserInfo] = useState([]);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [hasMascotasInfo, setHasMascotasInfo] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!userId) return;
    
                const userInfoQuery = query(collection(db, "usuariosMascotas"), where("userId", "==", userId));
                const userInfoSnapshot = await getDocs(userInfoQuery);
    
                if (userInfoSnapshot.empty) {
                    console.log("No se encontró información de usuario en la colección usuariosMascotas para este userId:", userId);
                    return;
                }
    
                const userInfoData = userInfoSnapshot.docs[0].data();
    
                const mascotasInfoQuery = query(collection(db, "informacionMascotas"), where("userId", "==", userId));
                const mascotasInfoSnapshot = await getDocs(mascotasInfoQuery);
    
                if (!mascotasInfoSnapshot.empty) {
                    let allMascotasData = [];

                    mascotasInfoSnapshot.forEach(doc => {
                        allMascotasData.push(doc.data());
                    });
    
                    const userInfo = {
                        ...userInfoData,
                        informacionMascotas: allMascotasData
                    };
    
                    setUserInfo(userInfo);
                    setHasMascotasInfo(true);
                } else {
                    console.log("No se encontró información de mascotas en la colección informacionMascotas para este userId:", userId);
                    setUserInfo(userInfoData);
                    setHasMascotasInfo(false);
                }
            } catch (error) {
                console.error("Error al obtener información de mascotas:", error);
            }
        };
    
        fetchData();
    }, [userId]);    

    const handleEdit = () => {
        setIsEditing(true);
        setEditedUserInfo({ ...userInfo });
    };
    
    const handleSave = async () => {
        try {
            await Promise.all(userInfo.informacionMascotas.map(async (mascota) => {
                const mascotaQuery = query(collection(db, "informacionMascotas"), where("userId", "==", userInfo.userId), where("nombres", "==", mascota.nombres));
                const mascotaSnapshot = await getDocs(mascotaQuery);
                if (!mascotaSnapshot.empty) {
                    mascotaSnapshot.forEach(async (doc) => {
                        await updateDoc(doc.ref, mascota);
                    });
                }
            }));
            setIsEditing(false);
            setSaveSuccess(true);
            setEditedUserInfo(null);
            setTimeout(() => {
                setSaveSuccess(false);
            }, 3000);
        } catch (error) {
            console.error("Error al guardar la información de las mascotas:", error);
        }
    };
    
    const handleInputChangeMascota = (e, index) => {
        const { name, value } = e.target;
        const updatedMascotas = [...editedUserInfo.informacionMascotas];
        updatedMascotas[index][name] = value;
        setEditedUserInfo(prevState => ({
            ...prevState,
            informacionMascotas: updatedMascotas
        }));
    };

    const handleInputChangeContactoEmergencia = (e, mascotaIndex, contactoIndex, fieldName) => {
        const { value } = e.target;
        const updatedMascotas = [...editedUserInfo.informacionMascotas];
    
        updatedMascotas[mascotaIndex][fieldName][contactoIndex] = value;
    
        setEditedUserInfo(prevState => ({
            ...prevState,
            informacionMascotas: updatedMascotas
        }));
    };

    const agregarContactoEmergencia = (mascotaIndex) => {
        const nuevasMascotas = [...editedUserInfo.informacionMascotas];
        nuevasMascotas[mascotaIndex].parentescoContactoEmergencia.push('');
        nuevasMascotas[mascotaIndex].nombresContactoEmergencia.push('');
        nuevasMascotas[mascotaIndex].celularContactoEmergencia.push('');
        setEditedUserInfo((prevState) => ({
          ...prevState,
          informacionMascotas: nuevasMascotas,
        }));
    };

    const eliminarContactoEmergencia = (mascotaIndex, contactoIndex) => {
        const nuevasMascotas = [...editedUserInfo.informacionMascotas];
        nuevasMascotas[mascotaIndex].parentescoContactoEmergencia.splice(contactoIndex, 1);
        nuevasMascotas[mascotaIndex].nombresContactoEmergencia.splice(contactoIndex, 1);
        nuevasMascotas[mascotaIndex].celularContactoEmergencia.splice(contactoIndex, 1);
        setEditedUserInfo((prevState) => ({
          ...prevState,
          informacionMascotas: nuevasMascotas,
        }));
    };
    
    const handleClickSiguiente = () => { 
        navigate('/informacion-mascotas', { state: { userId: userId } });
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            setIsAuthenticated(false);
            navigate("/iniciar-sesion-mascotas");
        } catch (error) {
            console.error("Error al cerrar sesión:", error);
        }
    };

    return (
        <div className="container mt-5">
            <Helmet>
                <title>Home | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            {isAuthenticated ? (
                <>
                    {userInfo ? (
                        <div className="container">
                            <div className="text-center mb-5">
                                <img src={logoICE} alt="Logo ICE" width="200px" />
                            </div>
                            {!isEditing ? (
                                <>
                                    {hasMascotasInfo ? (
                                        <>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="button" className="btn btn-primary" onClick={handleEdit}>
                                                        <FontAwesomeIcon icon={faEdit} /> Editar Información
                                                    </button>  
                                                </div>
                                                <div className="col-6 d-flex justify-content-end">
                                                    <button type="button" className="btn btn-secondary" onClick={handleLogout}>
                                                        <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar Sesión
                                                    </button>
                                                </div>
                                                <section>
                                                {saveSuccess && (
                                                    <div className="alert alert-success mt-3" role="alert">
                                                        ¡La información fue guardada exitosamente!
                                                    </div>
                                                )}
                                            </section>
                                            </div>
                                            <section className="mt-3 mb-3 form-informacion-personal p-3">
                                                <h1>Información Mascotas</h1>
                                                {userInfo.informacionMascotas && userInfo.informacionMascotas.map((mascota, index) => (
                                                    <div className="accordion" key={index}>
                                                        <div className="accordion-item mb-3">
                                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                                    <p>{mascota.nombres}</p>
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className="mb-5">
                                                                        <img src={mascota.foto} className="rounded mb-3" alt="Foto de la mascota" width="150px"/>
                                                                        <p>Nombre: {mascota.nombres}</p>
                                                                        <p>Edad: {mascota.edad}</p>
                                                                        <p>Raza: {mascota.raza}</p>
                                                                        <p>Alimento: {mascota.alimento}</p>
                                                                        <p>Medicamentos: {mascota.medicamentos}</p>
                                                                        <p>Cuidados Especiales: {mascota.cuidadosespeciales}</p>
                                                                        <a className="btn btn-primary" href={mascota.plandevacunacion} role="button"><FontAwesomeIcon icon={faFile} /> Plan de vacunación</a>
                                                                    </div>
                                                                    <div>
                                                                        <h2>Contactos de emergencia</h2>
                                                                        {mascota.parentescoContactoEmergencia.map((parentesco, index) => (
                                                                            <div className="card mb-3" key={index}>
                                                                                <div className="card-header">
                                                                                    {parentesco}
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <p>{mascota.nombresContactoEmergencia[index]}</p>
                                                                                    <p>{mascota.celularContactoEmergencia[index]}</p>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </section>
                                            <button type="button" className="btn btn-primary" onClick={handleClickSiguiente}>Agregar mascota</button> 
                                        </div>
                                        </>
                                        ) : (
                                        <>
                                        <div className="">
                                            <p>Hola {userInfo.nombre}, por favor registra tus mascotas</p>
                                            <button type="button" className="btn btn-primary" onClick={handleClickSiguiente}>Agregar mascota</button>
                                        </div>
                                        </>
                                    )}
                                </>
                                ) : (
                                    <>
                                        <div className="containter">
                                            <div className="row mb-5">
                                                <div className="col-6">
                                                    <button type="button" className="btn btn-primary" onClick={handleSave}>
                                                        <FontAwesomeIcon icon={faSave} /> Actualizar información
                                                    </button>  
                                                </div>
                                                <div className="col-6 d-flex justify-content-end">
                                                    <button type="button" className="btn btn-secondary" onClick={handleLogout}>
                                                        <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar Sesión
                                                    </button>
                                                </div>
                                            </div>
                                            <form className="form-informacion-personal p-3">
                                                {userInfo.informacionMascotas && userInfo.informacionMascotas.map((mascota, index) => {
                                                    const editedMascotas = editedUserInfo?.informacionMascotas || [];
                                                    return (
                                                        <div className="mb-5" key={index}>
                                                            <h2>Editar información de {mascota.nombres}</h2>
                                                            <div>
                                                                <img src={mascota.foto} className="rounded mb-3" alt="Foto de la mascota" width="150px"/>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label htmlFor="nombres" className="col-3 col-form-label">Nombre</label>
                                                                <input 
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="nombres"
                                                                    value={editedMascotas[index]?.nombres || ""} 
                                                                    onChange={(e) => handleInputChangeMascota(e, index)} 
                                                                    placeholder="¿Cuál es su nombre?"
                                                                />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label htmlFor="raza" className="col-3 col-form-label">Raza</label>
                                                                <input 
                                                                    type="text"
                                                                    name="raza"
                                                                    value={editedMascotas[index]?.raza || ""} 
                                                                    onChange={(e) => handleInputChangeMascota(e, index)} 
                                                                    className="form-control" 
                                                                />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label htmlFor="edad" className="col-3 col-form-label">Edad</label>
                                                                <input 
                                                                    type="text"
                                                                    name="edad"
                                                                    value={editedMascotas[index]?.edad || ""} 
                                                                    onChange={(e) => handleInputChangeMascota(e, index)} 
                                                                    className="form-control" 
                                                                    placeholder="Ej: 1 año"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label htmlFor="alimento" className="col-3 col-form-label">Alimento</label>
                                                                <input 
                                                                    type="text"
                                                                    name="alimento"
                                                                    value={editedMascotas[index]?.alimento || ""} 
                                                                    onChange={(e) => handleInputChangeMascota(e, index)} 
                                                                    className="form-control" 
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label htmlFor="medicamento" className="col-3 col-form-label">Medicamento</label>
                                                                <input 
                                                                    type="text"
                                                                    name="medicamentos"
                                                                    value={editedMascotas[index]?.medicamentos || ""} 
                                                                    onChange={(e) => handleInputChangeMascota(e, index)} 
                                                                    className="form-control" 
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label htmlFor="cuidadosespeciales" className="col-3 col-form-label">Cuidados especiales</label>
                                                                <input 
                                                                    type="text"
                                                                    name="cuidadosespeciales"
                                                                    value={editedMascotas[index]?.cuidadosespeciales || ""} 
                                                                    onChange={(e) => handleInputChangeMascota(e, index)} 
                                                                    className="form-control" 
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mt-5">
                                                                <h2>Contactos de emergencia</h2>
                                                                {mascota.parentescoContactoEmergencia.map((parentesco, contactoIndex) => (
                                                                    <div className="card mb-3" key={index}>
                                                                        <div className="card-header">
                                                                            <div>
                                                                                <input 
                                                                                    type="text"
                                                                                    name={`parentescoContactoEmergencia[${index}]`}
                                                                                    value={mascota.parentescoContactoEmergencia[contactoIndex]}
                                                                                    onChange={(e) => handleInputChangeContactoEmergencia(e, index, contactoIndex, 'parentescoContactoEmergencia')}
                                                                                    className="form-control"
                                                                                    placeholder="Parentesco"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="mb-3">
                                                                                <input 
                                                                                    type="text"
                                                                                    name={`nombresContactoEmergencia[${index}]`}
                                                                                    value={mascota.nombresContactoEmergencia[contactoIndex]}
                                                                                    onChange={(e) => handleInputChangeContactoEmergencia(e, index, contactoIndex, 'nombresContactoEmergencia')} 
                                                                                    className="form-control" 
                                                                                    placeholder="Nombre contacto emergencia"
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <input 
                                                                                    type="text"
                                                                                    name={`celularContactoEmergencia[${index}]`}
                                                                                    value={mascota.celularContactoEmergencia[contactoIndex]}
                                                                                    onChange={(e) => handleInputChangeContactoEmergencia(e, index, contactoIndex, 'celularContactoEmergencia')} 
                                                                                    className="form-control" 
                                                                                    placeholder="Celular contacto emergencia"
                                                                                />
                                                                            </div>  
                                                                        </div>
                                                                        <div className="card-footer row">
                                                                            <div className="col-6">
                                                                                <button type="button" className="btn btn-success" onClick={handleSave}>Actualizar este contacto</button>
                                                                            </div>
                                                                            <div className="col-6 d-flex justify-content-end">
                                                                                <button type="button" className="btn btn-danger" onClick={() => eliminarContactoEmergencia(index, contactoIndex)}>Eliminar este contacto</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="mt-5">
                                                                    <button type="button" className="btn btn-primary" onClick={() => agregarContactoEmergencia(index)}>Agregar otro contacto</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </form>
                                        </div>
                                    </>
                                )}      
                        </div>
                    ) : (
                        <div className="container mt-5">
                            <p>Cargando información...</p>
                        </div>
                    )}
                </>
            ) : (
                // Si no está autenticado
                <div className="container mt-5">
                    <p>Por favor inicia sesión haciendo click <Link to="/iniciar-sesion-mascotas">aquí</Link>.</p>
                </div>
            )}
        </div>
    );
}

export default HomeMascotas;
