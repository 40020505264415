import React, { useState } from 'react';
import logoICE from '../assets/images/Logo-ICE-Negativo-02.png'
import { Helmet } from 'react-helmet';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from "../firebase/firebaseconfig";
import { sha256 } from 'js-sha256'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const LoginMascotas = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const q = query(collection(db, 'usuariosMascotas'), where('email', '==', email));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                setError("El usuario no existe");
                return;
            }
    
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();
            const hashedPassword = sha256(password);
            if (userData.password !== hashedPassword) {
                setError("Contraseña incorrecta");
                return;
            }
    
            console.log("Inicio de sesión exitoso");
            navigate(`/home-mascotas`, { state: { userId: userDoc.id } });
        } catch (error) {
            console.error("Error al iniciar sesión:", error);
            setError("Error al iniciar sesión");
        }
    };

    return (
        <div className="container-fluid vh-100 w-100 d-flex justify-content-center align-items-center login-mascotas">
            <Helmet>
                <title>Iniciar Sesión | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div>
                <div className="form-login p-4">
                    <div className="text-center">
                        <img src={logoICE} width="200px" alt="Logo ICE"></img>
                        <h1 className="mt-2 mb-4">Iniciar sesión</h1>
                    </div>
                    <form>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                            <input 
                                type="email"
                                id="email"
                                className="form-control" 
                                placeholder="tu@email.com"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FontAwesomeIcon icon={faLock} /></span>
                            <input 
                                type="password"
                                id="contraseña"
                                className="form-control"
                                placeholder="Tu contraseña"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {error && <div className="text-danger mb-3">{error}</div>}
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-success" onClick={handleLogin}>Iniciar Sesión</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginMascotas;