import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './user/home';
import Register from './auth/register';
import Login from './auth/login';
import InformacionPersonal from './components/form-informacion-personal';
import AdminLogin from './admin/auth/login';
import Dashboard from './admin/dashboard';
import InfoQR from './qr-info';
import MascotasQR from './qr-mascotas';
import HomeMascotas from './mascotas/home';
import RegisterMascotas from './auth/register-mascotas';
import LoginMascotas from './auth/login-mascotas';
import InformacionMascotas from './components/form-informacion-mascotas';

const App = () => {
    const [userId] = useState(null);

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home userId={userId} />} />
                <Route path="/registro/:idUnico" element={<Register />} />
                <Route path="/iniciar-sesion" element={<Login />} />
                <Route path="/informacion-personal" element={<InformacionPersonal userId={userId} />} />
                <Route path="/qr/:idUnico" element={<InfoQR />} />
                <Route path="/qr-mascotas/:idUnico" element={<MascotasQR />} />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/home-mascotas" element={<HomeMascotas userId={userId} />} />
                <Route path="/registro-mascotas" element={<RegisterMascotas />} />
                <Route path="/iniciar-sesion-mascotas" element={<LoginMascotas />} />
                <Route path="/informacion-mascotas" element={<InformacionMascotas userId={userId} />} />
            </Routes>
        </Router>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);