import React, { useState } from "react";
import '../assets/css/global.css';
import logoICE from '../assets/images/Logo-ICE-Negativo-02.png';
import { Helmet } from 'react-helmet';
import { db } from "../firebase/firebaseconfig";
import { collection, addDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import { sha256 } from 'js-sha256';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock, faCircleRight } from '@fortawesome/free-solid-svg-icons';

const RegisterMascotas = () => {
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setError("Las contraseñas no coinciden");
            return;
        }

        if (!nombre || !email || !password) {
            setError("Por favor, complete todos los campos");
            return;
        }

        setIsLoading(true);

        try {
            const correoQuery = query(collection(db, "usuariosMascotas"), where("email", "==", email));
            const correoSnapshot = await getDocs(correoQuery);
            if (!correoSnapshot.empty) {
                setError("Ya existe un usuario con este correo electrónico");
                return;
            }

            const hashedPassword = sha256(password);
            const usersRef = collection(db, "usuariosMascotas");
            const docRef = await addDoc(usersRef, {
                nombre: nombre,
                email: email,
                password: hashedPassword
            });

            const userId = docRef.id;
            await updateDoc(docRef, { userId: userId }); // Actualizar el documento con el userId

            console.log("Datos de registro almacenados en Firestore con el userId:", userId);

            setRegistrationSuccess(true);

            setTimeout(() => {
                navigate(`/iniciar-sesion-mascotas/`);
            }, 3000);
        } catch (error) {
            console.error("Error al almacenar los datos en Firestore:", error);
            setError("Error al registrar el usuario. Por favor, inténtelo de nuevo más tarde");
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div className="container-fluid vh-100 w-100 register p-5">
            <Helmet>
                <title>Registro | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="row">
                <div className="col-sm-6">
                    <div className="text-center mb-3">
                        <img src={logoICE} alt="Logo ICE" width="200px"></img>
                    </div>
                    <div>
                        <p>Tu mascota es parte de tu familia. Con ICE, puedes garantizar su seguridad registrándola fácilmente. En caso de que se pierda, estarás a solo un escaneo de distancia para ser contactado por quien la encuentre."</p>
                    </div>
                </div>
                <div className="col-sm-6 p-3 form-register p-4">
                    <h1 className="mb-3 text-center">Registro</h1>
                    <form>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FontAwesomeIcon icon={faUser} style={{ color: 'white' }}/></span>
                            <input 
                                type="text"
                                id="textl"
                                className="form-control" 
                                placeholder="Tu nombre"
                                required
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FontAwesomeIcon icon={faEnvelope} style={{ color: 'white' }}/></span>
                            <input 
                                type="email"
                                id="email"
                                className="form-control" 
                                placeholder="tu@email.com"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FontAwesomeIcon icon={faLock} style={{ color: 'white' }}/></span>
                            <input 
                                type="password"
                                id="contraseña"
                                className="form-control"
                                placeholder="Crea una contraseña"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><FontAwesomeIcon icon={faLock} style={{ color: 'white' }}/></span>
                            <input 
                                type="password"
                                id="confirmar-contraseña"
                                className="form-control"
                                placeholder="Confirma la contraseña"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        {error && <div className="text-danger mb-3">{error}</div>}
                        <div className="mb-3">
                            <p>Al hacer clic en «Registrarme», aceptas las <a href="https://ice.ghsoft.co/wp-content/uploads/2024/04/condiciones-de-uso-ice.pdf" className="alert-link">condiciones de uso</a>, y la <a href="https://ice.ghsoft.co/wp-content/uploads/2024/04/tratamiento-de-datos-personales-ice.pdf" className="alert-link">política de tratamiento de datos personales</a>.</p>
                        </div>
                        {error && <div className="text-danger mb-3">{error}</div>}
                        {registrationSuccess && ( 
                            <div className="alert alert-success" role="alert">
                                ¡Registro exitoso! Para agregar los datos de tus mascotas por favor inicia sesión
                            </div>
                        )}
                        <div className="d-flex justify-content-end">
                        <button 
                            type="button" 
                            className="btn btn-success mb-3" 
                            onClick={handleRegister} 
                            disabled={isLoading || registrationSuccess}
                        >
                            {isLoading ? "Registrando" : "Registrarme"} <FontAwesomeIcon icon={faCircleRight} />
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RegisterMascotas;