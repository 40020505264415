import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/firebaseconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/admin/dashboard');
        } catch (error) {
        console.error('Error al iniciar sesión:', error.message);
        }
    };

    return (
        <div className="containter-sm mx-5 my-5">
            <form onSubmit={handleLogin}>
                <div className="input-group mb-3">
                    <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                    <input 
                        type="email"
                        id="email"
                        className="form-control" 
                        placeholder="tu@email.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text"><FontAwesomeIcon icon={faLock} /></span>
                    <input 
                        type="password"
                        id="contraseña"
                        className="form-control"
                        placeholder="Tu contraseña"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div>
                    <button type="submit" className="btn btn-success">Iniciar Sesión</button>
                </div>
            </form>
        </div>
    );
}

export default AdminLogin;