import React, { useState } from 'react';
import logo from '../assets/images/Logo-ICE-QR.jpg';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import QRCode from 'qrcode';
import { auth, db } from '../firebase/firebaseconfig';
import { collection, addDoc } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
    const [idUnicoInformacionPersonal, setIdUnicoInformacionPersonal] = useState('');
    const [idUnicoInformacionMascotas, setIdUnicoInformacionMascotas] = useState('');
    const [codigosQRInformacionPersonal, setCodigosQRInformacionPersonal] = useState({ registro: null, usuario: null });
    const [codigosQRInformacionMascotas, setCodigosQRInformacionMascotas] = useState({ registro: null, usuario: null });
    const navigate = useNavigate();
    const [usuario, loading] = useAuthState(auth);

    const guardarId = async (id) => {
        try {
            const datosAGuardar = {
                id: id
            };
    
            const docRef = await addDoc(collection(db, 'idsMascotas'), datosAGuardar);
    
            console.log('ID guardado en Firestore con ID de documento:', docRef.id);
        } catch (error) {
            console.error('Error al guardar el ID en Firestore:', error);
        }
    };
    

    const handleLogout = () => {
        auth.signOut();
        navigate('/admin/login');
    };

    if (!usuario && !loading) {
        navigate('/admin/login');
        return null;
    }

    const generarIdUnico = () => {
        const longitudDeseada = 10;
        let nuevoId = Math.random().toString(36).substring(2); 
        while (nuevoId.length < longitudDeseada) {
            nuevoId = "0" + nuevoId;
        }
        nuevoId = nuevoId.substring(0, longitudDeseada);
        return nuevoId;
    };    

    const generarCodigoQR = async (tipo, idUnico, urlRegistro, urlUsuario) => {
        if (idUnico) {
            try {
                const url = tipo === 'registro' ? urlRegistro : urlUsuario;
                const canvas = document.createElement('canvas');
                canvas.width = 500;
                canvas.height = 500;
                const context = canvas.getContext('2d');

                const codigoQR = await QRCode.toDataURL(url, {
                    width: 500,
                    height: 500,
                    margin: 0,
                    color: {
                        dark: '#000',
                        light: '#fff',
                    },
                    background: '#fff',
                    quietZone: 1,
                });

                const img = new Image();
                img.src = codigoQR;
                await new Promise(resolve => { img.onload = resolve; });

                context.drawImage(img, 0, 0);

                if (tipo === 'usuario') {
                    const logoImg = new Image();
                    logoImg.src = logo;
                    await new Promise(resolve => { logoImg.onload = resolve; });
                    const logoX = (canvas.width - logoImg.width) / 2;
                    const logoY = (canvas.height - logoImg.height) / 2;
                    context.drawImage(logoImg, logoX, logoY, logoImg.width, logoImg.height);
                }

                return canvas.toDataURL();
            } catch (error) {
                console.error('Error al generar el código QR:', error);
                return null;
            }
        }
    };

    const handleGenerarQRInformacionPersonal = async () => {
        const idUnico = generarIdUnico();
        setIdUnicoInformacionPersonal(idUnico);
        const urlRegistro = `app.ice.ghsoft.co/registro/${idUnico}`;
        const urlQR = `app.ice.ghsoft.co/qr/${idUnico}`;
        const codigoQRregistro = await generarCodigoQR('registro', idUnico, urlRegistro, urlQR);
        const codigoQRusuario = await generarCodigoQR('usuario', idUnico, urlQR, urlQR);
        setCodigosQRInformacionPersonal({ registro: codigoQRregistro, usuario: codigoQRusuario });
    };

    const handleGenerarQRInformacionMascotas = async () => {
        const idUnico = generarIdUnico();
        setIdUnicoInformacionMascotas(idUnico);
        const urlRegistro = 'app.ice.ghsoft.co/registro-mascotas';
        const urlQR = `app.ice.ghsoft.co/qr-mascotas/${idUnico}`;
        const codigoQRregistro = await generarCodigoQR('registro', idUnico, urlRegistro, '');
        const codigoQRusuario = await generarCodigoQR('usuario', idUnico, '', urlQR);
        setCodigosQRInformacionMascotas({ registro: codigoQRregistro, usuario: codigoQRusuario });
    };

    const descargarQR = (codigoQR, nombreArchivo) => {
        const enlaceDescarga = document.createElement('a');
        enlaceDescarga.href = codigoQR;
        enlaceDescarga.download = nombreArchivo;
        enlaceDescarga.click();
    };

    const handleDescargarQRs = async (tipo, idUnico) => {
        const codigoQR = tipo === 'informacionPersonal' ? codigosQRInformacionPersonal : codigosQRInformacionMascotas;
        descargarQR(codigoQR.registro, `${idUnico}_${tipo}_registro.jpg`);
        if (tipo === 'informacionPersonal') {
            descargarQR(codigoQR.usuario, `${idUnico}_${tipo}_usuario.jpg`);
        } else {
            descargarQR(codigoQR.usuario, `${idUnico}_${tipo}_usuario.jpg`);
        }
    };

    return (
        <div className="container mt-5 mb-5">
            <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-secondary" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar Sesión
                </button>
            </div>
            <section>
                <h2>QRs información personal</h2>
                <button onClick={handleGenerarQRInformacionPersonal}>Generar QR</button>
                {idUnicoInformacionPersonal && <p>ID único generado: {idUnicoInformacionPersonal}</p>}
                {codigosQRInformacionPersonal.usuario && codigosQRInformacionPersonal.registro && (
                    <button onClick={() => handleDescargarQRs('informacionPersonal', idUnicoInformacionPersonal)}>Descargar QRs</button>
                )}
                {codigosQRInformacionPersonal.registro && <img src={codigosQRInformacionPersonal.registro} alt="Código QR Registro" />}
                {codigosQRInformacionPersonal.usuario && <img src={codigosQRInformacionPersonal.usuario} alt="Código QR Usuario" />}
            </section>
            <section>
                <h2>QRs información mascotas</h2>
                <button onClick={handleGenerarQRInformacionMascotas}>Generar QR</button>
                {idUnicoInformacionMascotas && <p>ID único generado: {idUnicoInformacionMascotas}</p>}
                {codigosQRInformacionMascotas.registro && (
                    <button onClick={() => {
                        handleDescargarQRs('informacionMascotas', idUnicoInformacionMascotas);
                        guardarId(idUnicoInformacionMascotas);
                    }}>Descargar QR</button>
                )}
                {codigosQRInformacionMascotas.registro && <img src={codigosQRInformacionMascotas.registro} alt="Código QR Registro" />}
                {codigosQRInformacionMascotas.usuario && <img src={codigosQRInformacionMascotas.usuario} alt="Código QR Usuario" />}
            </section>
        </div>
    );
};

export default Dashboard;