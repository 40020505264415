import React, { useState, useRef, useEffect } from 'react';
import logoICE from '../assets/images/Logo-ICE.png'
import { Helmet } from 'react-helmet';
import { opcionesPeso, opcionesEstatura, opcionesTipoSangre } from '../assets/js/scripts';
import { db } from "../firebase/firebaseconfig";
import { collection, addDoc } from "firebase/firestore";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const InformacionPersonal = () => {
    const location = useLocation();
    const userId = location.state?.userId;

    const navigate = useNavigate();

    const [contactos, setContactos] = useState([
        { id: 1, parentesco: '', nombresCE: '', celularCE: '', parentescoCE: '' } 
    ]);

    const [nombres, setNombres] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [tipoSangre, setTipoSangre] = useState('');
    const [peso, setPeso] = useState('');
    const [estatura, setEstatura] = useState('');
    const [condicionesMedicas, setCondicionesMedicas] = useState('Ninguna');
    const [alergiasYReaciones, setAlergiasYReacciones] = useState('Ninguna');
    const [medicamentos, setMedicamentos] = useState('Ninguno');

    const nombresCERef = useRef([]);
    const celularCERef = useRef([]);
    const parentescoCERef = useRef([]);

    const agregarContacto = () => {
        const nuevoId = contactos.length + 1;
        const nuevoContacto = { id: nuevoId, parentesco: '', nombresCE: '', celularCE: '', parentescoCE: '' };
        setContactos([...contactos, nuevoContacto]);
    };
    
    const handleParentescoChange = (event, contactoId) => {
        const updatedContactos = contactos.map(contacto => {
          if (contacto.id === contactoId) {
            return { ...contacto, parentesco: event.target.value };
          }
          return contacto;
        });
        setContactos(updatedContactos);
    };

    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");


    const guardarInformacion = async () => {
        if (!nombres || !fechaNacimiento || !tipoSangre || !peso || !estatura) {
            setError("Por favor, complete todos los campos obligatorios.");
            return;
        }
        
        setIsLoading(true);
        
        try {
            const informacionPersonal = {
                nombres: nombres,
                fechanacimiento: fechaNacimiento,
                tiposangre: tipoSangre,
                peso: peso,
                estatura: estatura,
                condicionesmedicas: condicionesMedicas,
                alergiasyreacciones: alergiasYReaciones,
                medicamentos: medicamentos,
                nombresContactoEmergencia: nombresCERef.current.map(ref => ref.value),
                celularContactoEmergencia: celularCERef.current.map(ref => ref.value),
                parentescoContactoEmergencia: parentescoCERef.current.map(ref => ref.value),
                userId: userId,
            };
    
            const datosAGuardar = {
                userId: userId,
                registrado: true,
                ...informacionPersonal,
            };
    
            const docRef = await addDoc(collection(db, "informacionPersonal"), datosAGuardar);
            console.log('Información guardada exitosamente en Firestore con ID: ', docRef.id);
            console.log('La información a guardar es: ', datosAGuardar);
            setRegistrationSuccess(true);
        } catch (error) {
            console.error('Error al guardar la información:', error);
            setError("No se pudo guardar la información. Por favor, inténtalo de nuevo más tarde.");
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        if (registrationSuccess) {
            const redirectTimer = setTimeout(() => {
                navigate(`/iniciar-sesion`);
            }, 10000); 
    
            return () => clearTimeout(redirectTimer);
        }
    }, [registrationSuccess, navigate]);

    return (
        <div className="container-xxl mt-3">
            <Helmet>
                <title>Informacion personal | ICE</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="text-center">
                <img src={logoICE} alt="Logo ICE" width="200px"></img>
            </div>
            <form className="form-informacion-personal p-3 mt-3">
                <h1 className="mb-3">Información Personal</h1>
                <div className="mb-3">
                    <input 
                        type="text"
                        value={nombres}
                        onChange={(e) => setNombres(e.target.value)}
                        className="form-control" 
                        placeholder="Nombre y apellido"
                        required
                    />
                </div>
                <div className="row mb-3">
                    <label htmlFor="fechanacimiento" className="col-5 col-form-label">Fecha de nacimiento *</label>
                    <div className="col-7">
                        <input 
                            type="date"
                            value={fechaNacimiento}
                            onChange={(e) => setFechaNacimiento(e.target.value)}
                            className="form-control"
                            required 
                        />
                    </div>
                 </div>
                <div className="row mb-3">
                    <label htmlFor="tipoSangre" className="col-5 col-form-label">Tipo de sangre *</label>
                    <div className="col-7">
                        <select
                            id="tipoSangre" 
                            className="form-select"
                            value={tipoSangre}
                            onChange={(e) => {setTipoSangre(e.target.value);}}
                            required
                        >
                            <option disabled value="">Selecciona...</option>
                            {opcionesTipoSangre.map((opcion, index) => (
                                <option key={index} value={opcion.value}>
                                    {opcion.label}
                                </option>
                             ))}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="peso" className="col-5 col-form-label">Peso *</label>
                    <div className="col-7">
                        <select 
                            id="peso" 
                            className="form-select" 
                            value={peso}
                            onChange={(e) => setPeso(e.target.value)}
                            required
                        >
                            <option disabled value="">Selecciona...</option>
                            {opcionesPeso.map((opcion, index) => (
                                <option key={index} value={opcion}>
                                    {opcion}
                                </option>
                             ))}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="estatura" className="col-5 col-form-label">Estatura *</label>
                    <div className="col-7">
                        <select 
                            id="estatura" 
                            className="form-select" 
                            value={estatura}
                            onChange={(e) => setEstatura(e.target.value)}
                            required
                        >
                            <option disabled value="">Selecciona...</option>
                            {opcionesEstatura.map((opcion, index) => (
                                <option key={index} value={opcion}>
                                    {opcion}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="condicionesMedicas" className="col-12 col-form-label">Condiciones médicas</label>
                    <textarea
                        id="condicionesMedicas"
                        className="form-control"
                        value={condicionesMedicas}
                        onChange={(e) => setCondicionesMedicas(e.target.value)} 
                        rows="1">     
                    </textarea>
                </div>
                <div className="mb-3">
                    <label htmlFor="alergiasYReaciones" className="col-12 col-form-label">Alergias y reacciones</label>
                    <textarea
                        id="alergiasYReaciones"
                        className="form-control"
                        value={alergiasYReaciones}
                        onChange={(e) => setAlergiasYReacciones(e.target.value)} 
                        rows="1">     
                    </textarea>
                </div>
                <div className="mb-3">
                    <label htmlFor="notasMedicas" className="col-12 col-form-label">Medicamentos</label>
                    <textarea
                        id="notasMedicas"
                        className="form-control"
                        value={medicamentos}
                        onChange={(e) => setMedicamentos(e.target.value)} 
                        rows="1">     
                    </textarea>
                </div>
                <div className="mt-5">
                    <h2 className="mb-3">Contactos de emergencia</h2>
                    {contactos.map(contacto => (
                    <div className="accordion mb-5" key={contacto.id}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${contacto.id}`}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${contacto.id}`} aria-expanded="true" aria-controls={`collapse${contacto.id}`}>
                                    Contacto #{contacto.id} - {contacto.parentesco}
                                </button>
                            </h2>
                            <div id={`collapse${contacto.id}`} className="accordion-collapse collapse show" aria-labelledby={`heading${contacto.id}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="p-1">
                                        <div className="input-group mb-3">
                                            <input 
                                                type="text"
                                                id="nombresCE"
                                                ref={el => nombresCERef.current[contacto.id - 1] = el}
                                                className="form-control" 
                                                placeholder="Nombres"
                                                required
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input 
                                                type="number"
                                                id="celularCE"
                                                ref={el => celularCERef.current[contacto.id - 1] = el}
                                                className="form-control" 
                                                placeholder="Celular"
                                                required
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input 
                                                type="text"
                                                id="parentescoCE"
                                                ref={el => parentescoCERef.current[contacto.id - 1] = el}
                                                value={contacto.parentesco}
                                                onChange={(e) => handleParentescoChange(e, contacto.id)}
                                                className="form-control" 
                                                placeholder="Parentesco"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
                <div>
                    <button type="button" className="btn btn-primary" onClick={agregarContacto}>Agregar otro contacto</button>
                </div>
                {registrationSuccess && ( 
                    <div className="alert alert-success mt-5" role="alert">
                        ¡Información guardada con éxito! A partir de ahora, al escanear tus stickers, tus datos estarán disponibles para acceso rápido en caso de emergencia. Si necesitas editar o actualizar tu información en cualquier momento, puedes iniciar sesión con tu usuario y contraseña.
                    </div>
                )}
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="d-flex justify-content-end">
                    <button 
                        type="button" 
                        className="btn btn-success mt-5 mb-3" 
                        onClick={guardarInformacion} 
                        disabled={isLoading || registrationSuccess}
                    >
                        {isLoading ? "Guardando info..." : "Guardar Información"}
                </button>
                </div>
            </form>
        </div>
    );

}

export default InformacionPersonal;